import { send } from "../index";

export function getByVerificationCode(data, opts = {}) {
    // 根据核销码获取订单
    return send({
        url: "/admin/order/getByVerificationCode.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function verificationOrderBase(data, opts = {}) {
    // 订单核销
    return send({
        url: "/admin/orderBase/verification.do",
        method: "POST",
        data,
        ...opts,
    });
}